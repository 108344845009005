<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      persistent
      :fullscreen="isPhone"
      :width="dialogWidth"
      overlay-opacity="0.97"
      @keydown.esc="cancel"
    >
      <v-card
        class="d-flex flex-column charcoal overflow-y-auto noscroll-x"
        height="90vh"
        v-touch:swipe.left="cancel"
      >
        <BaseCloseDialogHeader heading="Add Activity" @cancel="cancel" />
        <BaseLoading :loaded="loaded" label="Loading Activities" />
        <v-card-text v-if="loaded" class="silver--text py-0 px-2 ">
          <template v-if="lastList.length > 0 && !showFullList">
            <v-card-text
              :class="textSizeSmall + ' silver--text pt-0 pl-0  mb-n4'"
            >
              Recent Activities
            </v-card-text>
            <v-row dense class="charcoal ">
              <v-col cols="12" class="pa-0">
                <v-card
                  rounded
                  elevation="0"
                  class=" py-1 mb-2 mx-1 charcoal charcoalTileMenu"
                  v-for="item in lastList"
                  :key="item.name"
                  @click="selectActivity(item, false)"
                >
                  <v-card-text class="py-0 px-2">
                    <span title="Measurement Type" class="paper--text">
                      <span> </span>
                      <v-spacer />
                      {{ capitalize(item.text) }}
                      <v-spacer />
                    </span>
                    <span
                      v-if="item.met > 0"
                      class="caption progressActive--text"
                    >
                      <v-icon color="progressActive" class="ml-n1">
                        mdi-fire
                      </v-icon>
                      {{ item.met }} METS
                    </span>
                    <v-card-actions class="silver--text py-0 px-0">
                      <span class="caption silver--text">
                        {{ tracks(item) }}
                      </span>
                      <v-spacer />
                      {{ item.type }}, {{ item.category }}
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <v-card-actions class="py-0 mb-n2 mt-n4">
            <v-spacer />
            <v-switch
              dark
              color="progressActive"
              v-model="showFullList"
              @change="showFakeLoadProgress"
              label="Show Full Activity List?"
            />
          </v-card-actions>
          <BaseLoading :loaded="loaded" label="Loading Activities" />
          <template v-if="(loaded && showFullList) || lastList.length == 0">
            <v-card-actions class="pt-0 mb-4">
              <BaseSearch
                dark
                :items="loadedList"
                :label="'Search ' + activityList.length + ' Activities'"
                what="text"
                @search_results="setResults"
              />
            </v-card-actions>
            <v-row dense class="charcoal">
              <v-col cols="12" class="px-0">
                <v-card
                  rounded
                  elevation="0"
                  class=" py-1 mb-2 mx-1 charcoal charcoalTileMenu"
                  v-for="item in activityList"
                  :key="item.name"
                  @click="selectActivity(item, true)"
                >
                  <v-card-text class="py-0">
                    <span title="Measurement Type" class="paper--text">
                      <span> </span>
                      <v-spacer />
                      {{ capitalize(item.text) }}
                      <v-spacer />
                    </span>
                    <span
                      class="caption progressActive--text"
                      v-if="item.met > 0"
                    >
                      <v-icon color="progressActive" class="ml-n1">
                        mdi-fire
                      </v-icon>
                      {{ item.met }} METS
                    </span>
                    <v-card-actions class="silver--text py-0 px-0">
                      <span class="caption silver--text">
                        {{ tracks(item) }}
                      </span>
                      <v-spacer />
                      {{ item.type }}, {{ item.category }}
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-spacer />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSelectedActivityDialog"
      :fullscreen="isPhone"
      width="850"
      persistent
      overlay-opacity="1"
      v-if="selectedActivity != null"
      @keydown.esc="cancel"
    >
      <v-card
        color="charcoal"
        tile
        flat
        class="d-flex flex-column noscroll px-2"
      >
        <BaseCloseDialogHeader
          heading="Log New Activity"
          @cancel="cancelLogNew"
        />
        <v-card-actions class="py-0 px-2">
          <v-spacer />
          <v-switch
            dark
            v-model="metric"
            :label="'Metric?'"
            class="pa-0 ma-0"
            color="accent"
            @change="calculate"
          />
        </v-card-actions>
        <v-sheet
          color="charcoal"
          class="pa-2 ma-2 my-0 silver--text"
          :style="charcoalTileMenu"
        >
          <v-card-text class="paper--text pa-0">
            <span :class="textSize">You've Selected</span>
          </v-card-text>
          <v-card-text :class="textSizeSmall + ' silver--text pa-0 my-2'">
            <v-icon large color="silver" class="ml-n2">
              {{ selectedActivity.icon }}
            </v-icon>
            {{ capitalize(selectedActivity.text) }}
            <v-spacer />
            Using current weight of
            <span class="paper--text"
              >{{ metric ? weightMetric : weightImperial }}
              {{ unit(metric) }}</span
            >
          </v-card-text>
          <v-sheet
            rounded
            class="transparent py-4 px-2 charcoalTileMenu"
            v-if="totalCalories > 0"
          >
            <v-card-actions
              :class="textSizeSmall + ' progressActive--text pa-0'"
            >
              <v-icon large class="ml-n2" color="orange">mdi-fire</v-icon>
              <span class="mt-1"> {{ caloriesPerMinute }} KCals/Minute </span>
              <v-spacer />
              <span :class="textSizeLarge + ' progressActive--text'">
                {{ Math.round(totalCalories) }}
              </span>
              kCal
            </v-card-actions>
          </v-sheet>
          <v-card-text v-else class="silver--text caption pl-0 mt-2 pb-2">
            Fill the information below to calculate calories burned.
          </v-card-text>
        </v-sheet>
        <v-sheet
          class="transparent charcoalTileMenu py-2 mt-2 pt-4 mx-2"
          rounded
        >
          <v-row no-gutters class="px-2" v-if="selectedActivity.id === -1">
            <v-col cols="12">
              <v-text-field
                solo
                background-color="charcoal lighten-2"
                dark
                label="Details (Activity Name)"
                v-model="details"
                required
                class="enlarged-input-small"
                type="text"
                prepend-inner-icon="mdi-pencil"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
          <v-row dense class="px-2">
            <v-col cols="6">
              <span class="silver--text caption">Duration(Minutes)*</span>
              <v-text-field
                solo
                background-color="charcoal lighten-2"
                dark
                :error-messages="valueErrors"
                label="Duration (minutes)*"
                v-model="duration"
                required
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-clock"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
            <template v-if="selectedActivity.use_distance == 'Yes'">
              <v-col cols="6">
                <span class="silver--text caption"
                  >Distance ({{ distanceUnit(metric) }})</span
                >
                <v-text-field
                  solo
                  background-color="charcoal lighten-2"
                  dark
                  :error-messages="valueErrors"
                  :label="'Distance (' + distanceUnit(metric) + ')'"
                  ref="distance"
                  v-model="distance"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9].*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-tape-measure"
                  @focus="$event.target.select()"
                />
              </v-col>
            </template>
            <v-col cols="6" v-if="selectedActivity.category == 'Custom'">
              <span class="silver--text caption">Calories Burned*</span>
              <v-text-field
                solo
                background-color="charcoal lighten-2"
                dark
                :error-messages="valueErrors"
                label="Calories Burned*"
                v-model="totalCalories"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-fire"
                @focus="$event.target.select()"
                @input="calculate"
              />
            </v-col>
            <v-col cols="6">
              <span
                v-if="selectedActivity.use_meps == 'Yes'"
                class="silver--text caption"
              >
                MEPS
              </span>
              <v-text-field
                solo
                background-color="charcoal lighten-2"
                dark
                :error-messages="valueErrors"
                label="MEPS"
                v-model="meps"
                class="enlarged-input-small"
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                prepend-inner-icon="mdi-fire"
                @focus="$event.target.select()"
                v-if="selectedActivity.use_meps == 'Yes'"
              />
            </v-col>
            <template v-if="selectedActivity.use_hr == 'Yes'">
              <v-switch
                dark
                class="pa-0 ma-0 mb-n2"
                color="nicegreen"
                v-model="showHR"
                label="Show Heart Rate"
              />
              <template v-if="showHR">
                <v-col cols="6">
                  <span class="silver--text caption">Peak HR</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    dark
                    :error-messages="valueErrors"
                    label="Peak HR"
                    v-model="peak_hr"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="number"
                    prepend-inner-icon="mdi-heart-pulse"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6">
                  <span class="silver--text caption">Avg. HR</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    dark
                    :error-messages="valueErrors"
                    label="Avg. HR"
                    v-model="avg_hr"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="number"
                    prepend-inner-icon="mdi-heart-half-full"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" v-if="selectedActivity.show_effort === 'Yes'">
                  <span class="silver--text caption">Effort %</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-2"
                    dark
                    :error-messages="valueErrors"
                    label="Effort %"
                    v-model="effort"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-heart-flash"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </template>
            </template>
          </v-row>
          <v-row dense class="mr-1">
            <v-col cols="6"> </v-col>
            <v-col cols="6">
              <v-datetime-picker
                dark
                filled
                class="paper--text"
                color="paper"
                label="Start Time"
                v-model="selectedDateTime"
                :text-field-props="textFieldProps"
                :date-picker-props="dateProps"
                :time-picker-props="timeProps"
                time-format="HH:mm"
                date-format="yyyy-MM-dd"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="pa-0">
          <v-btn
            tile
            :disabled="
              !(
                weight > 0 &&
                duration > 0 &&
                (isValidDate(selectedDateTime) || isNow)
              )
            "
            height="70"
            large
            label="Save Meal"
            block
            tonal
            color="progressActive"
            @click="saveActivity"
          >
            <v-icon color="black" large>
              mdi-content-save-all
            </v-icon>
            <span class="text-h6"> Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  mixins: [validationMixin, util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar',
        dark: true
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        useSeconds: false,
        ampmInTitle: true,
        headerColor: 'charcoal'
      },
      loaded: false,
      showHR: false,
      saveWeight: false,
      showFullList: false,
      useFullList: false,
      showSelectedActivityDialog: false,
      selectedActivity: null,
      metric: false,
      caloriesPerMinute: 0,
      totalCalories: 0,
      meps: 0,
      distance: 0,
      duration: 0,
      peak_hr: 0,
      avg_hr: 0,
      effort: 0,
      weightObj: {},
      weight: '',
      details: null,
      weightMetric: null,
      weightImperial: null,
      activity: '',
      added: '',
      search: '',
      activityList: [],
      loadedList: [],
      lastList: [],
      datetime: null,
      selectedDateTime: null,
      api: null
    }
  },
  validations: {
    value: {
      required
    }
  },
  computed: {
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !(this.imperial > 0 || this.metric > 0) &&
        errors.push('Value is required.')
      return errors
    },
    itemsCount() {
      return this.activityList.length
    },

    ...appConfig
  },
  watch: {
    show: function(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (val) {
        this.metric = this.useMetric
        let formatted = moment().format('yyyy-MM-DD hh:mm')
        this.selectedDateTime = formatted

        this.loadLast()
        this.loadCurrentWeight()
        this.loadPage()
      } else this.loaded = false
    }
  },
  beforeMount() {
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: this.reqTimeout
    })
    this.metric = this.useMetric
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    tracks(item) {
      let text = 'Tracks '
      if (item.use_distance == 'Yes') text += 'Distance, '
      if (item.use_hr == 'Yes') text += 'Heart Rate, '
      if (item.use_meps == 'Yes') text += 'MEPS, '
      text = text.replace(/,\s*$/, '')
      if (text == 'Tracks ') return ''
      else return text
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    showFakeLoadProgress() {
      if (this.showFullList) this.loaded = false
      setTimeout(() => {
        this.loaded = true
      }, 300)
    },
    setResults(value) {
      this.activityList = value
    },
    cancelLogNew() {
      this.reset()
    },
    reset() {
      this.caloriesPerMinute = 0
      this.totalCalories = 0
      this.duration = 0
      this.distance = 0
      this.meps = 0
      ;(this.details = null), (this.peak_hr = 0)
      this.avg_hr = 0
      this.effort = 0
      this.selectedActivity = null
      this.details = null
      this.showHR = false
      this.selectedDateTime = ''
      this.datetime = ''
      this.search = ''
      this.showSelectedActivityDialog
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    calculate() {
      if (this.selectedActivity.id > 0)
        this.totalCalories = this.calculateCalories(
          this.selectedActivity.met,
          this.weightMetric,
          this.duration
        )

      this.caloriesPerMinute =
        this.duration == 0
          ? 0
          : this.twoDigits(this.totalCalories / this.duration)

      this.weightMetric = this.twoDigits(this.weightMetric)
      this.weightImperial = this.twoDigits(this.weightImperial)
    },
    selectActivity(value, fullList) {
      this.useFullList = fullList
      this.selectedActivity = value
      this.calculate()
      this.showSelectedActivityDialog = true
    },
    reloadWeight() {
      if (this.metric) {
        this.weight = this.weightObj.metric
        this.weightImperial = this.weightObj.imperial
        this.weightMetric = this.weightObj.metric
      } else {
        this.weight = this.weightObj.imperial
        this.weightImperial = this.weightObj.imperial
        this.weightMetric = this.weightObj.metric
      }
    },
    loadCurrentWeight() {
      return axios
        .get(this.baseURL + '/stats/weight/current', {})
        .then(response => {
          if (response.status == 200) {
            if (response.data.data.length > 0) {
              this.weightObj = response.data.data[0]
              this.reloadWeight()
            } else {
              this.saveWeight = true
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveActivity() {
      let distance_metric = 0
      let distance_imperial = 0
      if (this.metric) {
        distance_metric = this.distance
        distance_imperial = this.distance * this.kmToMiles()
      } else {
        distance_metric = this.distance / this.kmToMiles()
        distance_imperial = this.distance
      }
      distance_imperial = this.twoDigits(distance_imperial)
      distance_metric = this.twoDigits(distance_metric)

      let dateObj = new Date(this.selectedDateTime)
      this.datetime = this.formatDateTime(dateObj)

      return axios
        .post(this.baseURL + '/activities', {
          activityid: this.selectedActivity.id,
          calories: this.caloriesPerMinute,
          totalCalories: this.totalCalories,
          duration: this.duration,
          distance_imperial: distance_imperial,
          distance_metric: distance_metric,
          start_time: this.datetime,
          meps: this.meps,
          peak_hr: this.peak_hr,
          avg_hr: this.avg_hr,
          effort: this.effort,
          details: this.details
        })
        .then(response => {
          if (response.status == 200) {
            if (this.saveWeight) {
              this.saveTheWeight()
            } else this.toast('Activity added succesfully')
            this.reset()
            this.$emit('saved')
            this.$router.push('/activities')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    saveTheWeight() {
      let dateObj = new Date(this.selectedDateTime)
      this.datetime = this.formatDateTime(dateObj)
      return axios
        .post(this.baseURL + '/measurements', {
          measurementid: 1,
          metric: this.weightMetric,
          imperial: this.weightImperial,
          measured: this.datetime
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Activity added succesfully')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async loadLast() {
      try {
        this.loaded = false
        let recentActivities = localStorage.getItem('activities_recent')

        if (recentActivities != null) {
          this.lastList = JSON.parse(recentActivities)
          this.showFullList = this.lastList.length == 0
          this.loaded = true
        }
        const response = await this.api.get('/activities/last', {})
        if (response.status === 200) {
          this.lastList = response.data.data
          if (recentActivities == null) {
            this.showFullList = this.lastList.length == 0
          }
          localStorage.setItem(
            'activities_recent',
            JSON.stringify(this.lastList)
          )
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        null
      }
    },
    async loadPage() {
      try {
        let localActivities = localStorage.getItem('activities')
        if (localActivities != null) {
          this.activityList = JSON.parse(localActivities)
          this.loadedList = this.activityList.slice()
        }
        const response = await this.api.get('/activities/list/all', {})

        if (response.status === 200) {
          let fresh = response.data.data
          if (localActivities == null) {
            this.activityList = fresh
            this.loadedList = this.activityList.slice()
          }
          this.localUpdate('activities', fresh)
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
        }
      } catch (error) {
        null
      }
    }
  }
}
</script>
